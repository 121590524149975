<template>
  <div id="addressBook">
    <!-- <el-space wrap alignment="center">
      <el-card
        shadow="hover"
        v-for="i in 3"
        :key="i"
        class="box-card"
        style="width: 200px"
      >
      </el-card>
    </el-space> -->
    <div style="margin-bottom: 10px">
      <!-- <h5>通讯录信息</h5> -->
      <!-- <el-card shadow="hover">
        <el-row>
          <el-col :span="6">
            <el-statistic title="分组个数" :value="26" />
          </el-col>
          <el-col :span="6">
            <el-statistic title="联系人个数" :value="2688" />
          </el-col>
        </el-row>
      </el-card> -->
    </div>
    <!-- <el-card shadow="hover">
      <el-space wrap alignment="center">
        <el-button type="primary"
          ><i class="fa-solid fa-folder-open"></i>添加分组</el-button
        >
        <el-button type="primary"
          ><i class="fa-solid fa-up-long"></i>导出分组</el-button
        >
        <el-button type="primary"
          ><i class="fa-solid fa-user-plus"></i>添加联系人</el-button
        >
        <el-button type="danger"
          ><i class="fa-solid fa-trash-can"></i>删除</el-button
        >
        <el-button type="primary"
          ><i class="fa-solid fa-paper-plane"></i>发送短信</el-button
        >
      </el-space>
    </el-card> -->

    <div class="main-box" style="margin-top: 10px">
      <el-card shadow="hover" style="overflow-x: auto">
        <el-tree
          ref="treeRef"
          :data="tree_data"
          node-key="id"
          :load="loadNode"
          lazy
          @node-contextmenu="
            (e, data, node, self) => nodeContextmenu(e, data, node, self)
          "
          @node-click="handleNodeClick"
          @node-expand="handleNodeExpand"
          show-checkbox
        >
          <template #default="{ node, data }">
            <span v-if="data.data.type == 10">
              <i class="fa-solid fa-folder-open"></i>
              <span>{{ data.title }}</span>
            </span>
            <span v-if="data.data.type == 20">
              <i class="fa-solid fa-user"></i>
              <span>{{ data.title }}</span>
            </span>
            <span style="padding-left: 30px"
              ><span
                class="btnTxt"
                @click.stop="add(node, data)"
                v-if="data.data.type == 10"
                >{{ $t("新增") }}</span
              ><span
                class="btnTxt"
                @click.stop="edit(node, data)"
                v-if="node.level != 1"
                style="padding-left: 10px"
                >{{ $t("编辑") }}</span
              ><span
                class="btnTxt"
                @click.stop="removeNode(node, data)"
                v-if="node.level != 1"
                style="padding-left: 10px"
                >{{ $t("删除") }}</span
              ></span
            >
          </template>
        </el-tree>
      </el-card>
      <!-- 点击分组展示的内容 -->
      <el-card shadow="hover" style="overflow-x: auto" v-show="1">
        <div>
          <!-- <div>分组信息</div> -->
          <div style="padding: 10px 0">
            <el-space wrap alignment="center">
              <div style="display: flex">
                <el-button
                  type="primary"
                  :loading="btnLoading"
                  @click="exportSampleFile"
                  style="margin-right: 10px"
                  ><i class="fa-solid fa-file-arrow-down"></i
                  >{{ $t("下载示例文件") }}</el-button
                >
                <el-upload
                  v-model:file-list="fileList"
                  action="/api/api/uploadAddressBook"
                  :headers="{ Authorization: $store.state.token }"
                  name="addressBookFile"
                  accept=".xlsx"
                  :on-success="uploadSuccess"
                  :on-error="onError"
                  :on-progress="onProgress"
                >
                  <el-button type="primary"
                    ><i class="fa-solid fa-up-long"></i
                    >{{ $t("导入联系人") }}</el-button
                  >
                  <!-- <template #tip>
                  导入联系人
                </template> -->
                </el-upload>
              </div>

              <!-- <el-button type="primary"
                ><i class="fa-solid fa-pen-to-square"></i>确认编辑</el-button
              > -->
            </el-space>
          </div>
          <!-- <el-form :model="searchForm" inline>
            <el-form-item label="分组名称">
              <el-input
                v-model.trim="name"
                placeholder="分组名称"
                clearable
                @clear="name = ''"
              ></el-input>
            </el-form-item>
            <el-form-item label="分组类型">
              <el-select
                v-model="searchForm.channelPoolId"
                :placeholder="$t('请选择')"
                filterable
                clearable
                @clear="searchForm.channelPoolId = null"
              > -->
          <!-- <el-option
                  v-for="(item, key) in visibleOptions.channelPool"
                  :key="key"
                  :label="item.channelPoolName"
                  :value="item.ID"
                /> -->
          <!-- </el-select>
            </el-form-item>
          </el-form> -->
          <!-- <div>联系人个数：999</div> -->
        </div>
      </el-card>
      <!-- 点击联系人展示的内容 -->
      <el-card shadow="hover" style="overflow-x: auto" v-show="0">
        <div>
          <div>联系人信息</div>
          <!-- <el-button type="primary">共享</el-button>
          <el-button type="primary">取消共享</el-button> -->
          <!-- <el-form
            :model="searchForm"
            ref="ref2"
            :rules="rules"
            inline
            label-width="80px"
          >
            <el-form-item label="姓名" prop="name">
              <el-input v-model="name" placeholder="姓名"> </el-input>
            </el-form-item>
            <el-form-item label="首字母">
              <el-input v-model="name" placeholder="首字母"> </el-input>
            </el-form-item>
            <el-form-item label="手机号码" prop="name">
              <el-input v-model="name" placeholder="手机号码"> </el-input>
            </el-form-item>
            <el-form-item label="昵称">
              <el-input v-model="name" placeholder="昵称"> </el-input>
            </el-form-item>
            <el-form-item label="传真号码">
              <el-input v-model="name" placeholder="传真号码"> </el-input>
            </el-form-item>
            <el-form-item label="邮箱地址">
              <el-input v-model="name" placeholder="邮箱地址"> </el-input>
            </el-form-item>
            <el-form-item label="生日">
              <el-date-picker
                v-model="name"
                type="date"
                placeholder="选择日期"
              />
            </el-form-item>
            <el-form-item label="公司名称">
              <el-input v-model="name" placeholder="公司名称"> </el-input>
            </el-form-item>
            <el-form-item label="联系地址">
              <el-input v-model="name" placeholder="联系地址"> </el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                v-model="name"
                :rows="2"
                type="textarea"
                placeholder="备注"
              />
            </el-form-item>
          </el-form> -->
          <el-button type="primary"
            ><i class="fa-solid fa-floppy-disk"></i>编辑并保存</el-button
          >
        </div>
      </el-card>
      <!-- 通讯录发送短信 -->
      <!-- <el-card shadow="hover" style="overflow-x: auto" v-show="false">
        <div>
          <el-form :model="form" label-width="60px" ref="">
            <el-row>
              <el-col>
                <el-form-item :label="$t('发送内容')" label-width="100px" prop="">
                  <el-input
                    v-model="form.content"
                    :rows="2"
                    type="textarea"
                    placeholder=""
                    @input="contentChange"
                  />
                  <div style="font-size: 12px; color: #606266">
                    短信内容<span class="textStyle">{{
                      form.content.length
                    }}</span
                    >个字，短信签名 【<span class="textStyle">{{
                      smsSignatureNum
                    }}</span
                    >】个字。70个字以内算1条,超过70个字按每条67个字拆分，共拆分<span
                      class="textStyle"
                      >{{ smsNum }}</span
                    >条
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="发送时间" label-width="100px" prop="">
                  <el-date-picker
                    v-model="searchForm.startTime"
                    type="datetime"
                    placeholder="发送时间"
                    value-format="YYYY-MM-DDTHH:mm:ssZ"
                    :disabled-date="
                      (time) => {
                        return time > new Date();
                      }
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <el-form-item label="" prop="">
                  <el-button type="primary">通讯录发送</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </el-card> -->
    </div>
    <!-- 添加分组弹框 -->
    <el-dialog
      v-model="addGroupDialog"
      title=""
      width="500px"
      align-center
      @close="dialog_close"
    >
      <el-form :model="form" label-width="80" :rules="rules">
        <el-form-item :label="$t('名称')" prop="name">
          <el-input
            v-model.trim="form.name"
            :placeholder="$t('请输入')"
            clearable
            @clear="form.name = ''"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('手机号')"
          prop="mobile"
          v-if="form.type === 20"
        >
          <el-input v-model.trim="form.mobile" :placeholder="$t('请输入')">
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('分组类型')" prop="type">
          <el-select
            v-model="form.type"
            :placeholder="$t('请选择')"
            filterable
            clearable
            @clear="form.type = null"
          >
            <el-option :label="$t('分组')" :value="10" />
            <el-option :label="$t('联系人')" :value="20" />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addGroupDialog = false">{{
            $t("取消")
          }}</el-button>
          <el-button type="primary" @click="addSave">
            {{ $t("确定") }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "addressBook",
};
</script>
<script setup>
import { reactive, onBeforeMount, ref, onMounted, onActivated } from "vue";
import { useStore } from "vuex";
import {
  getAddressBookList,
  createAddressBook,
  updateAddressBook,
  deleteAddressBook,
  downloadDemoAddressBook,
} from "@/http/api/addressBook";
import { ElMessage, ElMessageBox } from "element-plus";
// import ContextMenu from "@imengyu/vue3-context-menu";
import { numberTool, idToName, binaryToExcelExport } from "@/utils/tool";

import { useI18n } from "vue-i18n";
const { t } = useI18n();
const store = useStore();

const treeRef = ref(null);

const searchForm = reactive({});
const addGroupDialog = ref(false);
const tree_data = ref([]);
const form = reactive({
  id: null,
  fatherId: null,
  name: "",
  mobile: "",
  type: 10,
});
onBeforeMount(async () => {
  init();
});
const init = async () => {
  const res = await getAddressBookList({ fatherId: 0 });
  if (res.code === 0) {
    tree_data.value = res.data.list || [];
  }
};

const rules = reactive({
  // name: [
  //   { required: true, message: "请输入姓名", trigger: "blur" },
  //   // { min: 3, max: 5, message: "Length should be 3 to 5", trigger: "blur" },
  // ],
  // mobile: [
  //   { required: true, message: "请输入手机号", trigger: "blur" },
  //   {
  //     pattern: /^1[3456789]\d{9}$/,
  //     message: "请输入正确的手机号",
  //     trigger: "blur",
  //   },
  // ],
});

const handleNodeClick = async (data) => {};

const handleNodeExpand = async (data, node, self) => {
  // let id = node.data.data.id;
  //
  // const res = await getAddressBookList({ fatherId: id });
  // if (res.code === 0) {
  //   var arr = res.data.list || [];
  //   treeRef.value.updateKeyChildren(node.key, arr);
  // }
};

const defaultProps = {
  children: "children",
  label: "title",
  isLeaf: "leaf",
};
// 加载数据
const loadNode = async (node, resolve) => {
  if (node.level == 0) {
    return resolve(tree_data.value);
  }
  let id = node.data.data.id;
  const res = await getAddressBookList({ fatherId: id });
  if (res.code === 0) {
    var arr = res.data.list || [];
    resolve(arr);
  }
};
let key; //变更函数所使用的key
let fatherId;
const add = (node, data) => {
  key = node.key;

  if (data.data.type == 20) {
    return;
  }

  flag = "add";
  form.fatherId = data.data.fatherId;
  fatherId = data.data.fatherId;
  addGroupDialog.value = true;
};
// 编辑节点时需要通过父级id查询当前更新后的列表
const edit = (node, data) => {
  key = node.parent.key;

  flag = "edit";
  form.type = data.data.type;
  form.name = data.data.name;
  form.mobile = data.data.mobile;
  form.id = data.data.id;
  form.fatherId = data.data.fatherId;

  fatherId = node.parent.data.data.id;
  addGroupDialog.value = true;
};

let flag = "";
const addSave = async () => {
  let res;
  let obj = { ...form };
  if (obj.type == 10) {
    delete obj.mobile;
  }
  switch (flag) {
    case "add":
      delete form.id;
      res = await createAddressBook(form);
      break;
    case "edit":
      delete form.fatherId;
      res = await updateAddressBook(form);
      break;

    default:
      break;
  }
  if (res.code == 0) {
    const res2 = await getAddressBookList({ fatherId });
    if (res2.code === 0) {
      // 更新节点信息
      // setTimeout(() => {

      treeRef.value.updateKeyChildren(key, res2.data.list);
      // init()
      // }, 1000);
    }
    ElMessage({
      type: "success",
      message: t("操作成功"),
    });
    addGroupDialog.value = false;
  } else {
    ElMessage({
      type: "error",
      message: res.msg,
    });
  }
};
const nodeContextmenu = async (e, data, node, self) => {};

const removeNode = async (node, data) => {
  // ElMessageBox.confirm("确定删除吗?", "Warning", {
  //   confirmButtonText: "确定",
  //   cancelButtonText: "取消",
  //   type: "warning",
  // })
  //   .then(async () => {
  const res = await deleteAddressBook({ id: data.data.id });
  if (res.code === 0) {
    treeRef.value.remove(node);
    ElMessage({
      type: "success",
      message: t("删除成功"),
    });
  }
  // })
  // .catch(() => {
  //   ElMessage({
  //     type: "info",
  //     message: "已取消",
  //   });
  // });
};

const dialog_close = () => {
  addGroupDialog.value = false;
  form.type = 10;
  form.name = "";
  form.mobile = "";
  form.id = null;
  form.fatherId = null;
};

const btnLoading = ref(false);
const exportSampleFile = async () => {
  btnLoading.value = true;
  const res = await downloadDemoAddressBook();
  if (res && res instanceof Blob) {
    binaryToExcelExport(res, t("示例文件"));
  }
  btnLoading.value = false;
};
let fileList = ref([]); //文件列表
// 上传号码文件成功的钩子
const uploadSuccess = async (res, uploadFile, uploadFiles) => {
  if (res.code === 0) {
    init();
    ElMessage({
      message: t("上传成功"),
      type: "success",
    });
  }
};
// 上传失败钩子
const onError = (error) => {
  // ElMessage({
  //   message: "上传失败",
  //   type: "error"
  // });
};
//上传进度
const onProgress = async (evt, uploadFile, uploadFiles) => {
  // console.log(evt, uploadFile, uploadFiles);
};
</script>
  
  <style lang="scss" scoped>
#addressBook {
  padding: 0 30px;
  .main-box {
    display: flex;
    justify-content: space-between;
    .el-card {
      width: 49.8%;
    }
  }
}

::v-deep(.el-checkbox) {
  margin-bottom: 0 !important;
}

@media screen and (max-width: 768px) {
  #addressBook {
    padding: 0px;
    .main-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .el-card {
        width: 100%;
      }
    }
  }
}
.textStyle {
  font-weight: 900;
  font-size: 18px;
  color: red;
}

.el-tree-node__content {
  .btnTxt {
    font-size: 13px;
    visibility: hidden;
  }
  &:hover {
    .btnTxt {
      visibility: visible;
    }
  }
}
</style>
<style lang="scss">
</style>
  