import service from "@/http/request";

// 查询通讯录列表
export const getAddressBookList = (data) => {
  return service({
    url: "/api/getAddressBookList",
    method: "post",
    data
  });
};
// 新增通讯录
export const createAddressBook = (data) => {
  return service({
    url: "/api/createAddressBook",
    method: "post",
    data
  });
};
// 更新通讯录
export const updateAddressBook = (data) => {
  return service({
    url: "/api/updateAddressBook",
    method: "post",
    data
  });
};
// 删除通讯录
export const deleteAddressBook = (data) => {
  return service({
    url: "/api/deleteAddressBook",
    method: "post",
    data
  });
};
// 导出通讯录示例
export const downloadDemoAddressBook = (data) => {
  return service({
    url: "/api/downloadDemoAddressBook",
    method: "post",
    data,
    responseType: "blob"
  });
};
// 导入通讯录
export const uploadAddressBook = (data) => {
  return service({
    url: "/api/uploadAddressBook",
    method: "post",
    data,
  });
};